
import React, { useState } from 'react';

const ContactFormWithFlex = () => {
  const [result, setResult] = useState("");
  const [showOverlay, setShowOverlay] = useState(false); // Ensure overlay is hidden by default

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);
    formData.append("access_key", "c5af29df-36a8-42e2-8d91-574e8cd98352");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      setShowOverlay(true); // Show overlay on successful submission
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className='containerr'>
      <div className='md:flex'>
        <div className='md:pr-[3vw] md:w-1/3 md:border-r-[1px] border-[black] md:py-[50px]'>
          <h3>Send Us a Text</h3>
          <p>(806) 544-7572 | Mon - Sat (8am - 7pm)</p>
          <h3>We are also on Social Media</h3>
          <div className='flex gap-[7%] md:gap-[10%] md:mt-0'>
          <div className=''><a href='https://www.instagram.com/thenoodleworksco/profilecard/?igsh=aDNka3Zocjl4ZGd4'>
                <svg className='aspect-square h-[36px] sm:h-[50px]' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7017 0C6.63175 0 0 6.62287 0 14.7017V35.2983C0 43.3683 6.62287 50 14.7017 50H35.2983C43.3683 50 50 43.3771 50 35.2983V14.7017C50 6.63175 43.3771 0 35.2983 0H14.7017ZM14.7017 4.54545H35.2983C40.918 4.54545 45.4545 9.08203 45.4545 14.7017V35.2983C45.4545 40.918 40.918 45.4545 35.2983 45.4545H14.7017C9.08203 45.4545 4.54545 40.918 4.54545 35.2983V14.7017C4.54545 9.08203 9.08203 4.54545 14.7017 4.54545ZM38.4233 9.51705C37.2781 9.51705 36.3636 10.4315 36.3636 11.5767C36.3636 12.7219 37.2781 13.6364 38.4233 13.6364C39.5685 13.6364 40.483 12.7219 40.483 11.5767C40.483 10.4315 39.5685 9.51705 38.4233 9.51705ZM25 11.3636C17.4982 11.3636 11.3636 17.4982 11.3636 25C11.3636 32.5018 17.4982 38.6364 25 38.6364C32.5018 38.6364 38.6364 32.5018 38.6364 25C38.6364 17.4982 32.5018 11.3636 25 11.3636ZM25 15.9091C30.0515 15.9091 34.0909 19.9485 34.0909 25C34.0909 30.0515 30.0515 34.0909 25 34.0909C19.9485 34.0909 15.9091 30.0515 15.9091 25C15.9091 19.9485 19.9485 15.9091 25 15.9091Z" fill="black"/>
</svg></a>

                </div>
                <div className=''><a href=''>
                <svg className='aspect-square h-[36px] sm:h-[50px]' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 0C11.2151 0 0 11.2151 0 25C0 38.7849 11.2151 50 25 50C38.7849 50 50 38.7849 50 25C50 11.2151 38.7849 0 25 0ZM25 3.75C36.7583 3.75 46.25 13.2417 46.25 25C46.25 35.476 38.709 44.133 28.75 45.9009V31.25H33.2812C33.9113 31.25 34.444 30.7788 34.5215 30.1538L34.9902 26.4038C35.0352 26.0488 34.9226 25.6924 34.6851 25.4224C34.4488 25.1536 34.1087 25 33.75 25H28.75V20.625C28.75 19.2463 29.8713 18.125 31.25 18.125H33.75C34.44 18.125 35 17.5662 35 16.875V12.6562C35 12.0087 34.5061 11.4686 33.8623 11.4111C33.7886 11.4049 32.025 11.25 29.7925 11.25C24.2837 11.25 21.25 14.5202 21.25 20.459V25H16.25C15.56 25 15 25.5588 15 26.25V30C15 30.6912 15.56 31.25 16.25 31.25H21.25V45.9009C11.291 44.133 3.75 35.476 3.75 25C3.75 13.2417 13.2417 3.75 25 3.75Z" fill="black"/>
</svg>
</a>
                </div>
          </div>
        </div>

        <div className='md:pl-[3vw] md:w-2/3 py-[50px]'>
            <h3 className=''>Or shoot us an Email</h3>
        <form onSubmit={onSubmit} method="" className='text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-light'>

            <div className='flex gap-[2vw]'>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="name">Name</label><br/>
                    <input type='text' id='name' name='name' className='border-[1px] border-[#777777] px-[1em] py-[0.4em] w-full' required></input>
                </div>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="email">Email</label><br/>
                    <input type='email' id='email' name='email' className='border-[1px] border-[#777777] px-[1em] py-[0.4em] w-full' required></input>
                </div>
            </div>
            <div className='flex gap-[2vw]'>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="phone">Phone</label><br/>
                    <input type='tel' id='phone' name='phone' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className='border-[1px] border-[#777777] px-[1em] py-[0.4em] w-full' required></input>
                </div>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label for="company">Company</label><br/>
                    <input type='company' id='company' name='company' className='border-[1px] border-[#777777] px-[1em] py-[0.4em] w-full' required></input>
                </div>
            </div>
            <div className='text-left full py-[2vw]]'>
                <label for="name">How can we Help you?</label><br/>
                <textarea id='message' name='message' rows='5' className='border-[1px] border-[#777777] px-[1em] py-[0.4em] w-full box-border' required></textarea>
            </div>

            <div className='flex justify-center sm:justify-end mt-[0.5em]'>
                <button type='submit' className='btn submitBtn font-zen py-[0.3em] px-[2em]'>Shoot</button>
            </div>
        </form>




        </div> 
      </div>

      {/* Overlay for successful submission */}
      {showOverlay && (
    <div className="overlay fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 z-50 px-[10%] sm:px-0">
    <div className="overlay-content bg-[black] bg-opacity-[0.7] p-6 rounded-md text-center shadow-lg text-white border-[3px] border-[#FF8000]">
      <p className="text-center text-[36px] font-semibold mb-4 font-zen text-[#FF8000]">You made It!!</p>
      <p className='text-center'> Your message has found its way to us – we’ve got it covered, and you’ll hear from us soon!</p>
      <button
        onClick={() => setShowOverlay(false)}
        className="btn-solid close-btn mt-4 px-4 py-2 bg-[white] text-white rounded-md"
      >
        Close
      </button>
    </div>
  </div>
      )}




    </div>
  );
};

export default ContactFormWithFlex;
