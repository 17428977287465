import React from 'react'
import RoundButton from './RoundButton';

const HeroAbout = () => {
  return (
        <div className='relative'>
    <div className='absolute inset-0 flex justify-end sm:px-[5vw] '>
        <img className='h-[40vh] md:h-[60vh] relative  top-[45vh] md:top-[30vh]' src='./noodlebg.webp' alt='HeroBackground'></img>
    </div>
          <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[15vh] sm:py-[20vh]' data-scroll data-scroll-speed="3">
            <div className='HeroText text-center w-full'>
              <h1 className='text-center leading-[1em] text-[80px] md:text-[96px] lg:text-[120px] gradient-border'>About Us</h1>
                <p className='text-center lg:px-[10%] py-[2em]'>
                At NoodleWorks, we’re passionate about helping businesses succeed online. We saw an opportunity to support local brands by giving them something that’s often overlooked but essential: a website that truly reflects their values, story, and potential. Too many businesses struggle with sites that are outdated or don’t capture their unique strengths, so we set out to make web design approachable, powerful, and meaningful.
                </p>
            <RoundButton/>
            </div>
          </div>
        </div>
      
  );
}

export default HeroAbout
