import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';


import About from './pages/About';
import Work from './pages/Work';
import ContactUs from './pages/ContactUs';
import Homepage from './pages/Homepage';

function App() {


  return (
    
    <div className="App">
        
      

        <BrowserRouter>
      <Routes>
      <Route path="/" element={<Homepage />} /> 

        <Route path="/About" element={<About />} /> 
        <Route path="/Work" element={<Work />} /> 
        <Route path="/ContactUs" element={<ContactUs />} /> 


      </Routes>
    </BrowserRouter>
  
    </div>

  );
}

export default App;
