import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import AOS CSS

const WhoAreWeHomepage = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024); // Adjust the breakpoint as needed

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    // Update isSmallScreen on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
      AOS.refresh(); // Refresh AOS on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='sm:py-[50px] '>
      <div className='md:flex gap-[5vw] px-[5vw] md:px-0'>
        <div className='md:w-[55%] md:pl-[10vw] sm:py-[3em]'>
          <h3 className='py-0'>Who Are We?</h3>
          <p className=''>We are a team of passionate web experts, driven by <span className='font-semibold'>Grit</span>, <span className='font-semibold'>Trust</span>, and <span className='font-semibold'>Creativity</span>—the three core principles that Noodles Works is built on. Our mission is to help bring your ideas to life, building strong, lasting relationships along the way. With each project, we focus on delivering impactful results and providing support every step of your journey.</p>
          <a href='/About' className='text-[19px] md:text-[21px] lg:text-[25px] flex font-light leading-[1em] underline mt-[1em]'>Read More <div className='flex flex-col justify-center ml-[0.2em]'><svg className="w-[0.6em] h-[0.6em]" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.0208 1.68695C14.0208 1.13466 13.5731 0.686949 13.0208 0.686949L4.02082 0.686949C3.46853 0.686949 3.02082 1.13466 3.02082 1.68695C3.02082 2.23923 3.46853 2.68695 4.02082 2.68695H12.0208V10.6869C12.0208 11.2392 12.4685 11.6869 13.0208 11.6869C13.5731 11.6869 14.0208 11.2392 14.0208 10.6869L14.0208 1.68695ZM1.70711 14.4149L13.7279 2.39406L12.3137 0.979842L0.292893 13.0007L1.70711 14.4149Z" fill="black"/>
          </svg></div></a>
        </div>
        <div className='ml-[30%] w-[70%] mt-[2em] md:mt-0 md:ml-0 md:w-[45%]' data-scroll data-scroll-speed="1.5" data-aos={isSmallScreen ? "fade-up" : undefined} data-aos-once='true'>
          <img src='/computer.webp' className='greyScale w-full md:h-full border-[1px] border-black object-cover' alt='Who Are We'/>
        </div>
      </div>
    </div>
  )
}

export default WhoAreWeHomepage;