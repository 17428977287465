import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import AOS CSS

const HomepageStats = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024); // Adjust the breakpoint as needed

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    // Update isSmallScreen on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
      AOS.refresh(); // Refresh AOS on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='containerr px-[5vw] sm:px-[10vw]'>
      <div className='flex gap-[5vw]'>
        <div className='w-1/2' data-aos-once='true' data-aos={isSmallScreen ? "fade-right" : undefined}>
          <p className='text-left leading-[1em] text-[64px] sm:text-[80px] md:text-[96px] lg:text-[120px] txt-rotate text-gradient-sm font-semibold'> &gt;90%</p>
          <p className=''>of consumers go online to find local businesses</p>
        </div>
        <div className='w-1/2' data-aos-once='true' data-aos={isSmallScreen ? "fade-left" : undefined}>
          <p className='text-left leading-[1em] text-[64px] sm:text-[80px] md:text-[96px] lg:text-[120px] txt-rotate text-gradient-sm font-semibold'> &gt;70%</p>
          <p className=''>of consumers judge a company’s credibility based on their website design</p>
        </div>
      </div>
      <div className='inline-block w-[80%] sm:w-[40%] ml-[10%] sm:ml-[30%] text-left pt-[4em]' data-aos-once='true' data-aos={isSmallScreen ? "fade-up" : undefined}>
        <p className='font-medium'>—make sure your website makes the right impression.</p>
      </div>
    </div>
  );
}

export default HomepageStats;