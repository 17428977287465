import React from 'react'
import Header from  '../components/Header';
import HeroContact from '../components/HeroContact';
import Footer from  '../components/Footer';
import ContactFormWithFlex from '../components/ContactFormWithFlex';
import ScrollLayout from '../components/ScrollLayout';
import { useEffect } from 'react';

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact NoodleWorks';
  }, []);
  return (
    <div>
      <ScrollLayout>
        <Header />
        <HeroContact/>
        <ContactFormWithFlex/>
        <Footer />
      </ScrollLayout>
    </div>
  )
}

export default ContactUs
