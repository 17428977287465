import React from 'react';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Header = () => {
  const burgerMenuRef = useRef(null);
  const overlayRef = useRef(null);
  const location = useLocation();

  const handleBurgerMenuClick = () => {
    burgerMenuRef.current.classList.toggle("close");
    overlayRef.current.classList.toggle("overlay");
  };

  return (
    <div>
      <div className="header py-[1em] bg-[#FFFFFF] text-black relative text-[24px]">

        <div className="icons pl-[5vw]">
          <Link to='/' className='font-zen'>NoodleWorks</Link> {/* Changed to Link */}
        </div>

        <div id="burger-menu" ref={burgerMenuRef} className="md:hidden" onClick={handleBurgerMenuClick}>
          <span className="light-span"></span>
        </div>

        <div id="menu" ref={overlayRef}>
          <ul className="text-[white] px-[10vw] pt-[18vh] text-[48px] text-left">
            <li ><Link to="/About">About</Link></li>
            <li ><Link to="/Work">Our Work</Link></li>
            <li className='text-[#FF8000]'><Link to="/ContactUs">Connect</Link></li>
          </ul>
        </div>

        <ul className="headerlist hidden md:flex gap-[3vw] pr-[6vw] font-[300]">
          <li className={location.pathname === '/About' ? ' activeMenu flexCol' : 'flexCol underline-animation'}><Link to='/About'>About</Link></li> 
          <li className={location.pathname === '/Work' ? ' activeMenu flexCol' : 'flexCol underline-animation'}><Link to='/Work'>Work</Link></li> 
        </ul>
        
        <div className='hidden md:flex pr-[5vw] '>
          <Link className={location.pathname === '/ContactUs' ? ' bg-[#FF8000] text-[white] border-[1px] border-[#FF8000] text-[#FF8000]  rounded-[10px] font-[300] px-[1em] py-[0.2em]' : 'btn px-[1em] py-[0.2em]'} to='/ContactUs'>Connect</Link> 
        </div>
      </div>
    </div>
  )
}

export default Header;