import React from 'react'
import RoundButton from './RoundButton';
const HeroWork = () => {
    return (
        <div className='relative'>
    <div className='absolute inset-0 flex justify-end sm:px-[5vw]'>
        <img className='h-[40vh] md:h-[60vh] relative  top-[45vh] md:top-[30vh]' alt='HeroBackground' src='./noodlebg.webp'></img>
    </div>
          <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[15vh] sm:py-[15vh]'>
            <div className='HeroText text-center w-full' data-scroll data-scroll-speed="3">
              <h1 className='text-center leading-[1em] text-[80px] md:text-[96px] lg:text-[120px] gradient-border'>Our Work</h1>
                <p className='text-center lg:px-[10%] py-[2em]'>
                At The NoodleWorks, we’re passionate about helping you shine online! A strong online presence is crucial for your success, and we’re excited to offer a variety of uplifting services tailored to help your brand shine. Whether you’re looking to revamp your website, create captivating content, or build a distinctive brand identity, we’re here to support you every step of the way.                </p>
            <RoundButton/>
            </div>
          </div>
        </div>
      
  );
}

export default HeroWork
