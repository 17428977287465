import React from 'react'
import WhoAreWeHomepage from '../components/WhoAreWeHomepage';
import HomepageStats from '../components/HomepageStats';
import WhyUs from '../components/WhyUs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeroHomepage from '../components/HeroHomepage';
import WhatWeDo from '../components/WhatWeDo';
import ScrollLayout from '../components/ScrollLayout';
import { useEffect } from 'react';

const Homepage = () => {
    useEffect(() => {
        document.title = 'NoodleWorks';
      }, []);
  return (
    <div>
          <ScrollLayout>

<Header />
<HeroHomepage/>
<WhyUs />
<HomepageStats />
<WhoAreWeHomepage />
<WhatWeDo/>
<Footer />
</ScrollLayout>
    </div>
  )
}

export default Homepage
