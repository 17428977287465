import React from 'react'
const HeroContact = () => {
  return (
       <div className='relative'>
    <div className='absolute inset-0 flex justify-end sm:px-[10vw]'>
        <img className='h-[30vh] md:h-[50vh] relative  top-[30vh] md:top-[20vh]' src='./noodlebg.webp' alt='HeroBackground'></img>
    </div>
          <div className='relative w-screen overflow-x-hidden px-[5vw] sm:px-[10vw] py-[15vh] sm:py-[20vh]'>
            <div className='HeroText text-center w-full'>
              <h1 className=' text-left leading-[1.1em] text-[64px] md:text-[88px] lg:text-[120px] gradient-border' style={{ paddingLeft: 0, paddingRight: 0 }}>Ready to <br></br>Shine?</h1>
                <p className='text-left lg:w-[50%] py-[0.5em]'>
                We’re excited to partner with you on your journey. Reach out today, and let’s begin your transformation!  </p>
            </div>
          </div>
        </div>
  )
}

export default HeroContact
