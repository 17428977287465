import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import AOS CSS

const Values = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024); 
  useEffect(() => {
    AOS.init();

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
      AOS.refresh(); 
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='containerr'>
        <h3 className=''>Our Values</h3>
        <div className='py-[50px]'>
        <div  className='flex'>
            <div className='valuesTitle min-w-[1.2em] min-h-[210px] md:min-h-[250px] lg:min-h-[330px]' data-aos-once='true' data-aos={isSmallScreen ? 'fade-right' : undefined}>
            <p className=' px-0 leading-[1em] bottom-[-35%]'   >Trust</p>

            </div>
            <div className=' flex gap-[2vw]'>
                <div className='flex flex-col justify-end md:w-3/5'>
                    <p className='text-[14px] sm:text-[18px] md:text-[20px] lg:text-[24px]'>
                        We believe trust is the foundation of every great partnership. To us, trust means transparency, reliability, and always putting your best interests at heart. We’re committed to earning your confidence and standing by you every step of the way.
                    </p>
                </div>
                <div className='hidden md:block md:w-2/5'>
                    <img src='/trust.webp' className='greyScale w-full aspect-[4/3] border-[0.5px] border-black' alt='Trust'></img>

                </div>
            </div>
           
        </div>
        <div  className='flex mt-[70px]'>
            <div className='valuesTitle min-w-[1.2em] min-h-[345px] md:min-h-[411px] lg:min-h-[520px] relative' data-aos-once='true' data-aos={isSmallScreen ? 'fade-right' : undefined}>
                <p className=' px-0   leading-[1em] bottom-[-23%]'>Creative</p>

            </div>
            <div className=' flex gap-[2vw]'>
                <div className='flex flex-col justify-between'>
                    <img src='/creativity.webp' className='greyScale ml-[10%] md:ml-[10%] w-[80%] md:w-[60%] aspect-[5/4] md:aspect-[16/9] border-[1px] border-black' alt='Creativity'></img>


                    <p className='text-[14px] sm:text-[18px] md:text-[20px] lg:text-[24px]'>
                    Creativity is at the core of everything we do. We bring fresh perspectives, inventive ideas, and unique approaches to each project, helping your brand stand out and connect with your audience in memorable ways.
                    </p>
                </div>
         
            </div>
           
        </div>
        <div  className='flex mt-[70px]'>
            <div className='valuesTitle min-w-[1.2em] min-h-[165px] md:min-h-[200px] lg:min-h-[250px] relative' data-aos-once='true' data-aos={isSmallScreen ? 'fade-right' : undefined}>
                <p className=' px-0 leading-[1em] bottom-[-45%] sm:bottom-[-40%]'>Grit</p>

            </div>
            <div className=' flex gap-[2vw]'>
                <div className='flex flex-col justify-end md:w-3/5'>
                    <p className='text-[14px] sm:text-[18px] md:text-[20px] lg:text-[24px]'>
                    We’re driven by grit—always ready to go the extra mile to bring your vision to life. Every project is an opportunity to dig deep, overcome challenges, and build solutions that make a meaningful impact for you and your customers.</p>
                </div>
                <div className='hidden md:block md:w-2/5'>
                    <img src='/grit.webp' className='greyScale w-full aspect-[4/3] border-[1px] border-black' alt='Grit'></img>

                </div>
            </div>
           
        </div>
        </div>
      
    </div>
  )
}

export default Values
