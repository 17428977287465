import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import AOS CSS
const Services = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024); // Adjust the breakpoint as needed

    useEffect(() => {
      // Initialize AOS
      AOS.init();
  
      // Update isSmallScreen on window resize
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1024);
        AOS.refresh(); // Refresh AOS on resize
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (

    <div className='containerr'>
        <div  className='flex mt-[70px]'>
            <div className='valuesTitle min-w-[1.6em] lg:min-w-[2em] min-h-[320px] md:min-h-[383px] lg:min-h-[480px] relative 	'>
                <p className=' px-0   bottom-[-29%] leading-[1.2em] left-[-0.2em]'>Revamp</p>
            </div>
            <div className=' flex gap-[2vw]'>
                <div className='flex flex-col justify-end md:w-3/5'>
                    <div className=''>
                        <ul className='servicesList text-[18px] sm:text-[18px] md:text-[22px] lg:text-[26px] leading-[1.2em]'>
                            <li className=''>Complete Revamp of your existing Website</li>
                            <li className=''>Mobile Optimization</li>
                            <li className=''>Google Analytics Setup</li>
                            <li className=''>Content Cleanup & Formatting</li>
                            <li className=''>SEO optimization</li>
                        </ul>
                    </div>
                    <div className='ml-[-0.7em] text-[18px] sm:text-[22px] md:text-[26px] lg:text-[36px]'>
                        All this for Flat <soan className='text-gradient-sm text-[48px] sm:text-[60px] md:text-[64px] lg:text-[80px] font-bold pl-[0.1em]'> $99</soan>
                    </div>
                </div>
                <div className='hidden md:block md:w-2/5'>
                    <img src='/revamp.webp' className='greyScale w-full aspect-[3/4] border-[0.5px] border-black object-cover'></img>
                </div>
            </div>
        </div>
        <div className='ml-[30%] w-[70%] mt-[2em] md:hidden' data-aos={isSmallScreen ? "fade-up" : undefined} data-aos-once='true'>
          <img src='/revamp.webp' className='greyScale w-full aspect-[3/4] border-[0.5px] border-black object-cover' alt='Revamp'/>
        </div>
           
        <div className='py-[50px]'>
            <p className='solidBorderText'>Brand New</p>
            <div className=' flex gap-[2vw] pt-[2em]'>
                <div className='flex flex-col justify-end md:w-3/5'>
                    <div className='px-[1%]'>
                    <ul className=' servicesList text-[18px] sm:text-[18px] md:text-[22px] lg:text-[26px] leading-[1.3em] px-[1em] text-nowrap'>
                            <li className=''>Personalized Design from scratch</li>
                            <li className=''>5-6 pages</li>
                            <li className=''>Mobile Optimization</li>
                            <li className=''>Google Analytics Setup</li>
                            <li className=''>SEO optimization</li>
                        </ul>
                    </div>
                    <div className=' text-[18px] sm:text-[22px] md:text-[26px] lg:text-[36px]'>
                    All this for Flat <soan className='text-gradient-sm text-[48px] sm:text-[60px] md:text-[64px] lg:text-[80px] font-bold pl-[0.1em]'> $199</soan>
                    </div>
                </div>
                <div className='hidden md:block md:w-2/5'>
                    <img src='/computer.webp' className='greyScale w-full aspect-[4/3] border-[0.5px] border-black object-cover'></img>
                </div>
            </div>
            <div className=' w-[80%] mt-[2em] md:hidden' data-aos={isSmallScreen ? "fade-up" : undefined} data-aos-once='true'>
          <img src='/computer.webp' className='greyScale w-full aspect-[4/3] border-[0.5px] border-black object-cover' alt='New Website' />
        </div>
         
        </div>
    </div>
  )
}

export default Services
