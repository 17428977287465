import React from 'react'

const Process = () => {
  return (
    <div className='containerr'>
        <div className=''>        
            <h3 className='' data-scroll  data-scroll-repeat data-scroll-speed="2">Our Process</h3>
            <div className='grid sm:grid-cols-3 gap-[3vw] md:gap-[2vw] text-left'>
                <div className=''  data-scroll data-scroll-delay="1.5"  data-scroll-repeat data-scroll-speed="4">
                    <p className='font-medium'>
                        1.  Discover
                    </p>
                    <p className=''>
                    We start by understanding your brand, goals, and style. This sets us up to create a site that feels uniquely you.</p>
                </div>
                <div className=''  data-scroll data-scroll-delay="0.8" data-scroll-repeat data-scroll-speed="4">
                    <p className='font-medium'>
                        2.  Develop
                    </p>
                    <p className=''>
                    With your vision as our guide, we bring your site to life with custom designs that are beautiful, intuitive, and built to engage.</p>
                </div>
                <div className=''  data-scroll data-scroll-delay="0.1"  data-scroll-repeat data-scroll-speed="4">
                    <p className='font-medium'>
                        3.  Launch & Support
                    </p>
                    <p className=''>
                    It is just the beginning. We’re here to support you as your business grows, to keep things running smoothly.</p>
                </div>


            </div>


        </div>
      
    </div>
  )
}

export default Process
