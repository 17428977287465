import React from 'react';

const WhyUs = () => {

  

  return (
    <div>
      <div className="relative py-[50px] scroller-section w-screen overflow-x-hidden">
          <div className="panel" data-scroll data-scroll-direction="horizontal" data-scroll-speed="7" >
            <p className='text-center px-[0.2em] text-[30px] sm:text-[48px] md:text-[56px] lg:text-[72px] xl:text-[90px] font-medium leading-[1.1em] uppercase'>Complete Care • Complete Care • Complete Care • Complete Care • Complete Care • </p>
          </div>
          <div className="panel ml-[-100vw]" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-7">
            <p className='text-center px-[0.2em] text-[30px] sm:text-[48px] md:text-[56px] lg:text-[72px] xl:text-[90px] font-medium leading-[1.1em] uppercase'>Quality, Customized for You • Quality, Customized for You • Quality, Customized for You</p>
          </div>
          <div className="panel" data-scroll data-scroll-direction="horizontal" data-scroll-speed="7">
            <p className='text-center px-[0.2em] text-[30px] sm:text-[48px] md:text-[56px] lg:text-[72px] xl:text-[90px] font-medium leading-[1.1em] uppercase'>Transparent • Transparent • Transparent • Transparent • Transparent • Transparent • </p>
          </div>
          <div className="panel ml-[-100vw]" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-7">
            <p className='text-center px-[0.2em] text-[30px] sm:text-[48px] md:text-[56px] lg:text-[72px] xl:text-[90px] font-medium leading-[1.1em] uppercase'>Built for Growth • Built for Growth • Built for Growth • Built for Growth • Built for Growth • </p>
          </div>
        </div>
    </div>
  );
}

export default WhyUs;