import React from 'react';
import HeroWork from '../components/HeroWork';
import Header from  '../components/Header';
import Footer from  '../components/Footer';
import Services from '../components/Services';
import ScrollLayout from '../components/ScrollLayout';
import { useEffect } from 'react';


const Work = () => {
  useEffect(() => {
    document.title = 'Work Of NoodleWorks';
  }, []);
  return (
    <div>
    <ScrollLayout>
      <Header/>
      <HeroWork/>
      <Services/>
      <Footer/>
    </ScrollLayout>
    </div>
  )
}

export default Work
