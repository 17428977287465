import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import AOS CSS

const WhatWeDo = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024); // Adjust the breakpoint as needed

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    // Update isSmallScreen on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
      AOS.refresh(); // Refresh AOS on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='sm:py-[50px]'>
      <div className='md:flex gap-[5vw] px-[5vw] md:px-0'>
        <div className='w-[70%] mt-[2em] md:mt-0 md:ml-0 md:w-[45%]' data-scroll data-scroll-speed="1.5" data-aos={isSmallScreen ? "fade-up" : undefined} data-aos-once='true'>
          <img src='/business.webp' className='greyScale w-full w-full md:h-full border-[0.5px] border-black object-cover' alt='What We Do'/>
        </div>
        <div className='md:w-[55%] md:pr-[10vw]'>
          <h3 className='py-0 pt-[1em]'>What We Do?</h3>
          <p className=''>We’re passionate about helping local businesses thrive online. Our team reimagines websites to boost engagement and enhance user experience <br /><span className='font-medium'>—so you can focus on what you do best.</span></p>
          <a href='/Work' className='text-[19px] md:text-[21px] lg:text-[25px] flex font-light leading-[1em] underline mt-[1em]'>Read More <div className='flex flex-col justify-center ml-[0.2em]'><svg className="w-[0.6em] h-[0.6em]" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.0208 1.68695C14.0208 1.13466 13.5731 0.686949 13.0208 0.686949L4.02082 0.686949C3.46853 0.686949 3.02082 1.13466 3.02082 1.68695C3.02082 2.23923 3.46853 2.68695 4.02082 2.68695H12.0208V10.6869C12.0208 11.2392 12.4685 11.6869 13.0208 11.6869C13.5731 11.6869 14.0208 11.2392 14.0208 10.6869L14.0208 1.68695ZM1.70711 14.4149L13.7279 2.39406L12.3137 0.979842L0.292893 13.0007L1.70711 14.4149Z" fill="black" />
          </svg></div></a>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo;