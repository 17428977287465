import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

      <div className='mt-[50px] bg-[black] text-white '>
      <div className='relative'>
      <div className='picture-overlay w-full absolute h-full inset-0'>
      <img className='absolute object-cover w-full h-full' src='/preFooterBanner.webp' alt='Background'></img>
        <div className='dark-overlay absolute inset-0' ></div>
      </div>
      <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[5vh] sm:py-[10vh]'>
        <div className='HeroText text-center w-full'>
          <p className='text-center text-[white]  pb-[0.2em] text-[24px] sm:text-[26px] md:text-[28px] lg:text-[40px]  '>Not sure where to start?</p>
          <Link className='text-[20px] md:text-[24px] btn-solid px-[1em] py-[0.2em]' to='/ContactUs'>Get a Free Audit Today</Link> 
        </div>
      </div>
    </div>   

      <div className=' px-[5vw] sm:px-[10vw] py-[3em] md:flex gap-[5vw] justify-between'>
      
            <div className=''>           
                <h3 className='text-[20px] sm:text-[26px] md:text-[28px] lg:text-[32px] py-0'> NoodleWorks</h3>
                <p className='font-[100]'>Text us Today at <span className=' px-[0.2em]'> (806) 544-7572</span> and <br></br> Get Started! </p>
            </div>

            <div className='flex justify-center md:justify-end mt-[2em] md:mt-0'>
              <ul className='text-[16px] md:text-[18px] lg:text-[22px] font-medium'>
                <li className='leading-[1em] font-extralight	'>MENU</li>
                <li className='leading-[1em] mt-[1em]'><Link to="/About">About</Link></li>
                <li className='leading-[1em] mt-[1em] text-nowrap	'><Link to="/Work">Our Work</Link></li>


              </ul>
              <ul className='pl-[5em] text-[16px] md:text-[18px] lg:text-[22px] font-medium'>
                <li className='leading-[1em] text-[16px] md:text-[18px] lg:text-[22px] font-[100]	'>SOCIALS</li>


                <li className=''><a className='flex leading-[1em] mt-[1em] gap-[0.5em]' href='https://www.instagram.com/thenoodleworksco/profilecard/?igsh=aDNka3Zocjl4ZGd4'> <div className='flex flex-col justify-center ml-[0.2em]'><svg className='aspect-square w-[1em] ' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7017 0C6.63175 0 0 6.62287 0 14.7017V35.2983C0 43.3683 6.62287 50 14.7017 50H35.2983C43.3683 50 50 43.3771 50 35.2983V14.7017C50 6.63175 43.3771 0 35.2983 0H14.7017ZM14.7017 4.54545H35.2983C40.918 4.54545 45.4545 9.08203 45.4545 14.7017V35.2983C45.4545 40.918 40.918 45.4545 35.2983 45.4545H14.7017C9.08203 45.4545 4.54545 40.918 4.54545 35.2983V14.7017C4.54545 9.08203 9.08203 4.54545 14.7017 4.54545ZM38.4233 9.51705C37.2781 9.51705 36.3636 10.4315 36.3636 11.5767C36.3636 12.7219 37.2781 13.6364 38.4233 13.6364C39.5685 13.6364 40.483 12.7219 40.483 11.5767C40.483 10.4315 39.5685 9.51705 38.4233 9.51705ZM25 11.3636C17.4982 11.3636 11.3636 17.4982 11.3636 25C11.3636 32.5018 17.4982 38.6364 25 38.6364C32.5018 38.6364 38.6364 32.5018 38.6364 25C38.6364 17.4982 32.5018 11.3636 25 11.3636ZM25 15.9091C30.0515 15.9091 34.0909 19.9485 34.0909 25C34.0909 30.0515 30.0515 34.0909 25 34.0909C19.9485 34.0909 15.9091 30.0515 15.9091 25C15.9091 19.9485 19.9485 15.9091 25 15.9091Z" fill="white"/></svg></div>Instagram</a></li>        

                <li className=''><a className='flex leading-[1em] mt-[1em] gap-[0.5em]'> <div className='flex flex-col justify-center ml-[0.2em]'><svg className='aspect-square w-[1em] ' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 0C11.2151 0 0 11.2151 0 25C0 38.7849 11.2151 50 25 50C38.7849 50 50 38.7849 50 25C50 11.2151 38.7849 0 25 0ZM25 3.75C36.7583 3.75 46.25 13.2417 46.25 25C46.25 35.476 38.709 44.133 28.75 45.9009V31.25H33.2812C33.9113 31.25 34.444 30.7788 34.5215 30.1538L34.9902 26.4038C35.0352 26.0488 34.9226 25.6924 34.6851 25.4224C34.4488 25.1536 34.1087 25 33.75 25H28.75V20.625C28.75 19.2463 29.8713 18.125 31.25 18.125H33.75C34.44 18.125 35 17.5662 35 16.875V12.6562C35 12.0087 34.5061 11.4686 33.8623 11.4111C33.7886 11.4049 32.025 11.25 29.7925 11.25C24.2837 11.25 21.25 14.5202 21.25 20.459V25H16.25C15.56 25 15 25.5588 15 26.25V30C15 30.6912 15.56 31.25 16.25 31.25H21.25V45.9009C11.291 44.133 3.75 35.476 3.75 25C3.75 13.2417 13.2417 3.75 25 3.75Z" fill="white"/></svg></div>Facebook</a></li>

              </ul>
                
            </div>
       
      </div>
      <div className='pb-[2em]'>
              <p className='text-center text-[18px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-zen font-[500]'><span className='px-[1vw] sm:px-[3vw]'>Grit</span>  • <span className='px-[1vw] sm:px-[3vw]'>Trust</span> • <span className='px-[1vw] sm:px-[3vw]'>Creativity</span></p>
              <p className='text-[14px] sm:text-[16px] lg:text-[18px] text-center pt-[1em] font-[100]'>© {new Date().getFullYear()} The NoodleWorks - All Rights Reserved</p>
            </div>
    </div>
  )
}

export default Footer
