import React, { useEffect, useState } from 'react';
import RoundButton from './RoundButton';
const HeroHomepage = () => {
  const quotes = ["Grit", "Trust", "Creativity", "Noodle Works"];
  const [quoteIndex, setQuoteIndex] = useState(-1);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Check if the current quote is "NoodleWorks"
      if (quoteIndex < quotes.length - 1) {
        setFade(false);
        setTimeout(() => {
          setQuoteIndex((prevIndex) => prevIndex + 1);
          setFade(true);
        }, 500); // Time for fade out
      }
    }, 800); // Total time for each quote (fade in + display + fade out)

    return () => clearInterval(interval);
  }, [quoteIndex]);

  return (
    <div className='relative'>
<div className='absolute inset-0 flex justify-end sm:px-[5vw]'>
    <img className='h-[40vh] md:h-[60vh] relative  top-[45vh] md:top-[30vh]' alt='HeroBackground' src='./noodlebg.webp'></img>
</div>
      <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[15vh] sm:py-[20vh]'>
        <div className='HeroText text-center w-full'>
          <h1 className='text-center h-[270px] sm:h-[200px] md:h-[250px] lg:h-[300px]' >
            <span className='leading-[1em] text-[80px] md:text-[96px] lg:text-[120px] gradient-border '>The</span> <br />
            <span className={`leading-[1em] text-[80px] md:text-[96px]  lg:text-[120px] txt-rotate text-gradient font-semibold ${fade ? 'fade-in' : 'fade-out'}`}>
              {quotes[quoteIndex]}
            </span>
          </h1>

        <RoundButton/>
        </div>
      </div>
    </div>
  );
}

export default HeroHomepage;