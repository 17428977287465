import React from 'react';
import ScrollLayout from '../components/ScrollLayout';

import HeroAbout from '../components/HeroAbout';
import Header from '../components/Header';
import Values from '../components/Values';
import Process from '../components/Process';
import Footer from '../components/Footer';
import { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    document.title = 'About NoodleWorks';
  }, []);
  return (
    
    <div>
      <ScrollLayout>
        <Header/>
        <HeroAbout/>
        <Values />
        <Process />
        <Footer />
      </ScrollLayout>
    </div>
  )
}

export default About
